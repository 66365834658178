@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}


input { font-size: 16px!important; }

.radialgrad {
  /* background: radial-gradient(50% 50% at 50% 50%, #0e0e0e78 0%, #02020278 10%, #181614 100%); */
}

.radialgradcenter {
  background: radial-gradient(50% 50% at 50% 50%, #ce83f321 0%, #ce83f321 10%, rgba(20, 43, 114, 0) 100%);
}

.radialgradfoot {
  background: radial-gradient(50% 50% at 50% 100%, #8583f331 0%, #ce83f327 5%, rgba(20, 43, 114, 0) 100%);
}

.radialgradright {
  background: radial-gradient(50% 50% at 100% 50%, #8583f31c 0%, #ce83f319 20%, rgba(20, 43, 114, 0) 100%);
}

.radialgradbuttomright {
  background: radial-gradient(50% 50% at 100% 100%, #8583f31c 0%, #ce83f319 50%, rgba(20, 43, 114, 0) 100%);
}

.radialgradleft {
  background: radial-gradient(50% 50% at 0% 60%, #8583f31c 0%, #ce83f319 20%, rgba(20, 43, 114, 0) 100%);
}

.featuregrad {
  background-color: #141825;
  background-image: linear-gradient(180deg, #141825, #1418255c, #8583f353);
}

/* Set the color of the track (the area where the thumb doesn't appear) */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* ::-webkit-scrollbar-track {
  background-color: #8583f30e;
} */

/* Set the color and style of the scrollbar thumb (the draggable handle) */
/* ::-webkit-scrollbar-thumb {
  background-color: #89d7fb47;
  border-radius: 6px;
} */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden !important;
  background-color: #181614 !important;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.monts {
  font-family: "Montserrat", sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.flex-center {
  @apply flex items-center justify-center
}

.bluegrad {
  background-color: #89D6FB;
  background-image: linear-gradient(63deg, #89D6FB, #8583F3);
  color: black;
}

.fadetext {
  background-image: linear-gradient(180deg, #ffffff, rgba(255, 255, 255, 0.501), rgba(255, 255, 255, 0.05));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.bordergrad {
  border: 1px solid #83a6f3;
  color: #83a6f3;
}

.textgrad {
  background-image: linear-gradient(60deg, #89D6FB, #83a6f3, #83a6f3);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.bgblue {
  @apply bg-[#89d6fb]
}

.borderblue {
  @apply border-[#89d6fb]
}

.textblue {
  @apply text-[#89d6fb]
}

.stickyNav {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9999 !important;
}

.hotcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  width: 100vw;
  overflow: hidden;
}


.scroll-parent {
  position: relative;
  height: 100%;
  width: 100vw;
  /* max-width: 1200px; */
  margin: auto;
  margin-top: 20px;
  overflow-x: hidden;
}

.scroll-element {
  width: 200vw;
  /* max-width: 2400px; */
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.scroll-element div {
  width: 100%;
}

.primary {
  animation: primary 20s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

.circle {
  border-radius: 50%;
}

.hero-bg {
  background-image: radial-gradient(circle closest-corner at 60% 50%,
          #ce83f31b 41%,
          #0c0c0c2c);
}

/* MEDIA QUERIES */
@media (max-width: 639px) {
  .radialgrad {
    background: radial-gradient(50% 50% at 50% 50%, #ce83f316 0%, #ce83f316 30%, rgba(20, 43, 114, 0) 100%);
  }
  .hotcontainer {
    padding-inline: 20px;
  }
  .hotPresales {
      grid-auto-columns: 90%;
      gap: 5px;
  }
  .scroll-element {
      width: 500vw;
  }
  @keyframes primary {
      from {
        left: 0%;
      }
      to {
        left: -250%;
      }
    }
}

@media (max-width: 1170px) {
  .hotPresales {
      grid-auto-columns: 15%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input {
  background-color: #1e1e1e29 !important;
  border: 1px solid #4e4e4e29 !important;
  color: white !important;
  height: 96px !important;
  margin-bottom: 5px;
  font-size: 35px;
  border-radius: 12px !important;
}

.ant-input::placeholder {
  color: #5f6783 !important;
}

.ant-popover-arrow {
  visibility: hidden;
}

.ant-popover-title {
  color: white !important;
}

.ant-popover-inner-content {
  color: white !important;
}

.ant-popover-inner {
  min-width: 260px !important;
  min-height: 140px !important;
  border: 1px solid #21273a;
  background-color: #0e111b !important;
}

.ant-radio-group {
  margin-top: 10px;
}

.ant-radio-button-wrapper {
  background-color: #1f2639 !important;
  color: white !important;
  font-weight: 500;
  border-color: white !important;
}

.ant-radio-button-wrapper-checked {
  border-color: white !important;
  background-color: #363e54 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: white !important;
}

.ant-modal-content {
  background-color: #0e111b !important;
  color: white !important;
  padding: 0px !important;
}

.ant-modal {
  border: 1px solid #363e54;
  width: 400px !important;
  border-radius: 10px;
}

.ant-modal-header {
  background-color: #0e111b !important;
}

.ant-modal-title {
  color: white !important;
  padding-top: 17px !important;
  margin-left: 20px !important;
}

.ant-modal-close-icon {
  color: #363e54 !important;
  transition: 0.3s;
}

.ant-modal-close-icon:hover {
  color: white !important;
}

.backersMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  width: 100vw;
  overflow: hidden;
}
.scroll-parent {
  position: relative;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

.scroll-element a {
  width: 100%;
  margin-left: 50px;
}

.primary {
  animation: primary 15s linear infinite;
}

.scroll-element {
  width: 400vw;
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@keyframes primary {
    from {
        left: 0%;
    }

    to {
        left: -200%;
    }
}

/* MEDIA QUERIES */
@media (max-width: 639px) {
  
  section,
  nav,
  div,
  footer {
      overflow: hidden !important;
  }
  .roadCont {
      grid-auto-columns: 60%;
  }
  
  .hero-bg {
      top: -35%;
      right: -50%;
      z-index: 1;
  }
  .scroll-element {
      width: 800vw;
  }
  .primary {
      animation: primary 15s linear infinite;
  }
  @keyframes primary {
      from {
          left: 0%;
      }

      to {
          left: -400%;
      }
  }
}
